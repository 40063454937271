import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Terceros
import { AgGridModule } from 'ag-grid-angular';
import { RecaptchaModule } from 'ng-recaptcha';

// Locales
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProductosComponent } from './productos/productos.component';
import { InicioComponent } from './inicio/inicio.component';
import { DataService } from './data.service';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { EdoctaComponent } from './edocta/edocta.component';
import { registerLocaleData } from '@angular/common';
import localMx from '@angular/common/locales/es-MX';
import { ApartadoComponent } from './apartado/apartado.component';
import { DisponibleDirective } from './disponible.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogproductoComponent } from './dialogproducto/dialogproducto.component';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DialogfotoComponent } from './dialogfoto/dialogfoto.component';

// the second parameter 'es-MX' is optional
registerLocaleData(localMx, 'es-MX');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ProductosComponent,
    InicioComponent,
    SucursalesComponent,
    EdoctaComponent,
    ApartadoComponent,
    DisponibleDirective,
    DialogproductoComponent,
    DialogfotoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    RecaptchaModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  providers: [
    DataService,
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogproductoComponent, DialogfotoComponent]
})
export class AppModule { }
