import { Component, OnInit } from '@angular/core';
import { Categorias } from '../Models/categorias.model'
import { Foto } from '../Models/fotos.model'
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  categorias: Categorias[];
  scategorias: Categorias[];
  fotos: Foto[];

  constructor(private router: Router, private dataservice: DataService) { }

  ngOnInit() {
    this.dataservice.getCategorias()
    .subscribe(data => this.Despliega(data));
    this.dataservice.getFotos()
    .subscribe(data => this.fotos=data);
    this.dataservice.getSCategorias()
    .subscribe(data => this.scategorias=data);
  }

  BuscaProducto(par: string) {
    this.router.navigate(['/productos', 0, par]);
  }

  BuscaProductoCat(par: string) {
    this.router.navigate(['/productos', 2, par]);
  }

  BuscaProductoPalabras(palabras: string) {
    this.router.navigate(['/productos', 1, palabras]);
  }

  Despliega(data) {
    this.categorias= data;
  }
}
