import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Modelos
import { Producto } from './Models/productos.model';
import { Categorias } from './Models/categorias.model';
import { Sucursales } from './Models/sucursales.model';
import { EDOCta } from './Models/edocta.model';
import { Regresa } from './Models/regresa.model';
import { Foto } from './Models/fotos.model';

@Injectable({
  providedIn: 'root'
})



export class DataService {
  apiUrl = 'https://vivelatecnologia.com/apileo'; // Por si da problemas con el cors https://cors-anywhere.herokuapp.com/
  Primera = true;
  
  constructor(private http: HttpClient) { }

  getProductos(cat: string, subcat: string, subsubcat: string ) {

    return this.http.get<Producto[]>(this.apiUrl+"/productosweb.php?cat="+cat+"&subcat="+subcat+"&subsub="+subsubcat);
  }
  getProductosCat(cat: string, subcat: string ) {

    return this.http.get<Producto[]>(this.apiUrl+"/productoscat.php?cat="+cat+"&sub="+subcat);
  }
  getProducto(busca: string) {
    return this.http.get<Producto[]>(this.apiUrl+"/producto.php?busca="+busca);
  }

  getCategorias() {

    return this.http.get<Categorias[]>(this.apiUrl+"/categorias.php");
  }

  getSCategorias() {

    return this.http.get<Categorias[]>(this.apiUrl+"/supercat.php");
  }

  getProductosPalabras(palabras: string ) {

    return this.http.get<Producto[]>(this.apiUrl+"/productosbusca.php?busca=" + palabras);
  }

  getSucursales() {

    return this.http.get<Sucursales[]>(this.apiUrl+"/sucursales.php");
  }

  getSucursal(id: string) {

    return this.http.get<Sucursales[]>(this.apiUrl+"/sucursal.php?suc="+id);
  }

  getEdoCta( Sucursal: string, Folio: number, Serie: string, Prestamo: number) {

    return this.http.get<EDOCta[]>(this.apiUrl+"/edocta.php?suc=" + Sucursal + "&folio=" + Folio + "&serie=" + Serie + "&pres=" + Prestamo);
  }

   apartar( SucursalHost: string, Sucursal: string, Folio: number, Serie: string, Telefono: string) {
    
    const url = this.apiUrl+'/apartar.php?such=' + SucursalHost + "&suc=" + Sucursal + "&folio=" + Folio + "&serie=" + Serie + "&tel=" + Telefono
    return this.http.get<Regresa[]>(url);
  }

  getFotos() {

    return this.http.get<Foto[]>(this.apiUrl+"/fotosweb.php");
  }


}

