import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from '../Models/dialogproducto.model';

@Component({
  selector: 'app-dialogfoto',
  templateUrl: './dialogfoto.component.html',
  styleUrls: ['./dialogfoto.component.css']
})
export class DialogfotoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
  }

}
