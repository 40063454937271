import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Modelos
import { Sucursales } from '../Models/sucursales.model';

// Servicios
import { DataService } from '../data.service';

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.css']
})
export class SucursalesComponent implements OnInit {
  
  sucursales: Sucursales[];

  constructor(public sanitizer: DomSanitizer, private dataservice: DataService) { }

  ngOnInit() {
    return this.dataservice.getSucursales()
    .subscribe(data => this.sucursales = data);
  }

}
