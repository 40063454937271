import { Component, OnInit } from '@angular/core';
// instalar moment con 'npm install moment --save'
import * as moment from 'moment';
moment.defineLocale('es-MX', {
  parentLocale: 'es',
  /* */
});
// Modelos
import { EDOCta } from '../Models/edocta.model';
import { Sucursales } from '../Models/sucursales.model';

// Servicios
import { DataService } from '../data.service';
import { AngularFrameworkComponentWrapper } from 'ag-grid-angular';

@Component({
  selector: 'app-edocta',
  templateUrl: './edocta.component.html',
  styleUrls: ['./edocta.component.css']
})
export class EdoctaComponent implements OnInit {

  EdoCta: EDOCta[];
  sucursales: Sucursales[];

  columnDefs = [
    { headerName: 'Sucursal', field:'Sucursal', width:80 },
    { headerName: 'Folio', field:'Folio', width:80, type: "numericColumn" },
    { headerName: 'Serie', field:'Serie', width:60 },
    { headerName: 'Fecha', field:'Fecha', width:100, valueFormatter: this.dateFormatter},
    { headerName: 'Préstamo', field:'Préstamo', width:100, type: "numericColumn", valueFormatter: this.CurrencyCellRenderer },
    { headerName: 'Saldo', field:'Saldo', width:100, type: "numericColumn", valueFormatter: this.CurrencyCellRenderer },
    { headerName: 'Producto', field:'Producto', width:250, tooltipField: "Producto" },
    { headerName: 'Estatus', field:'Estatus', width:100 },
    { headerName: 'FechaUltima', field:'FechaUltima', width:100, valueFormatter: this.dateFormatter },
    { headerName: 'FechaVence', field:'FechaVence', width:100, valueFormatter: this.dateFormatter },
    { headerName: 'Refrendo', field:'Refrendo', width:100, type: "numericColumn", valueFormatter: this.CurrencyCellRenderer },
    { headerName: 'Desempeño', field:'Desempeño', width:100, type: "numericColumn", valueFormatter: this.CurrencyCellRenderer }

  ];
  
  constructor(private dataservice: DataService) { }

  ngOnInit() {
    return this.dataservice.getSucursales()
    .subscribe(data => this.sucursales = data);
 }

  EstadoCuenta(sucursal: string, folio: number, serie: string, prestamo: number) {
    
    (<HTMLInputElement>document.getElementById("folio")).value="";
    (<HTMLInputElement>document.getElementById("serie")).value="";
    (<HTMLInputElement>document.getElementById("prestamo")).value="";
    return this.dataservice.getEdoCta(sucursal, folio, serie, prestamo)
    .subscribe(data => this.EdoCta = data);  }

  dateFormatter(params) {
    return moment(params.value).format('DD/MMM/YYYY');
  }

  CurrencyCellRenderer(params: any) {
    var inrFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return inrFormat.format(params.value);
  }

  }
