import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDisponible]'
})
export class DisponibleDirective {

  constructor(el: ElementRef) {
    el.nativeElement.disable = true;
   }

   disponible(estado: boolean) {
   }
}
