import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Locales
import { DataService } from '../data.service';
import { Categorias } from '../Models/categorias.model'

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  categorias: Categorias[];

  constructor(private router: Router, private dataservice: DataService) { }

  ngOnInit() {
    this.dataservice.getCategorias()
    .subscribe(data => this.Despliega(data));
  }

  Despliega(data) {
    this.categorias= data;
    this.router.navigate(['/productos', 0, this.categorias[0].Par]);
  }

}

