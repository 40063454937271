import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { ProductosComponent } from './productos/productos.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { EdoctaComponent } from './edocta/edocta.component';
import { ApartadoComponent } from './apartado/apartado.component'


const routes: Routes = [
  { path: 'inicio', component: InicioComponent },
  { path: 'productos/:tipo/:busca', component: ProductosComponent },
  { path: 'sucursales', component: SucursalesComponent },
  { path: 'edocta', component: EdoctaComponent},
  { path: 'apartado/:busca', component: ApartadoComponent},
  { path: '**', component: InicioComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
