import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import {Location} from '@angular/common';

// Modelos
import { Producto } from '../Models/productos.model';
import { Regresa } from '../Models/regresa.model';
import { Sucursales } from '../Models/sucursales.model';

// Servicios
import { DataService } from '../data.service';


@Component({
  selector: 'app-apartado',
  templateUrl: './apartado.component.html',
  styleUrls: ['./apartado.component.css']
})
export class ApartadoComponent implements OnInit {

  producto: Producto[]=[];
  noDisponible: boolean = true;
  regresaAparta: Regresa[]=[];
  sucursales: Sucursales[]=[];
  
  constructor(private activatedRoute: ActivatedRoute, private dataservice: DataService, private _location: Location) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params => {
      return this.dataservice.getProducto(params.busca)
        .subscribe(data => this.producto = data);
    });
  }

  resolved(captchaResponse: string) {
    this.noDisponible=false;
  }

  fueratiempo(captchaResponse: string) {
    this.noDisponible=true;
  }

  aparta(SucursalHost: string, Sucursal: string, Folio: number, Serie: string, Telefono: string) {
    if (Telefono.length!=10) {
      
    } else {
    this.dataservice.apartar(SucursalHost, Sucursal, Folio, Serie, Telefono)
      .subscribe(data => this.regresaAparta=data);
      console.log("El apartado es:"+this.regresaAparta);
    this.dataservice.getSucursal(SucursalHost)
      .subscribe(data => this.sucursales=data);
    this.noDisponible = true;
    }
  };

  backClicked() {
    this._location.back();
  }
}
