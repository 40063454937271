import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// Modelos
import { Producto } from '../Models/productos.model';

// Servicios
import { DataService } from '../data.service';

// Componentes
import { DialogproductoComponent } from '../dialogproducto/dialogproducto.component';
import { DialogfotoComponent } from '../dialogfoto/dialogfoto.component';


@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})

export class ProductosComponent implements OnInit {

  productos: Producto[];
  descripcion: string;
  foto: string; 

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataservice: DataService, private dialog: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params => {
      switch (params.tipo) {
        case '0':
            let datos=params.busca.split(',')

            return this.dataservice.getProductos(datos[0],datos[1],datos[2])
            .subscribe(data => this.productos = data);
          break;
        case '1':
            return this.dataservice.getProductosPalabras(params.busca)
            .subscribe(data => this.productos = data);
          break;
          case '2':
            let datoss=params.busca.split(',')

            this.dataservice.getProductosCat(datoss[0],datoss[1])
            .subscribe(data => this.productos = data);
            console.log(this.productos);
             return;           
          break;
      };
    });
  }
 
  openDialog(descpro, fotopro): void {
    console.log("Entro al dialogo:",descpro,fotopro)
    const dialogRef = this.dialog.open(DialogproductoComponent, {
      width: '500px',
      data: {descripcion: descpro, foto: fotopro}
    });

  }
  openFoto(descpro, fotopro): void {
    console.log("Entro al abrefoto:",descpro,fotopro)
    const dialogRef = this.dialog.open(DialogfotoComponent, {
      width: '750px',
      data: {descripcion: descpro, foto: fotopro}
    });
 
  }
  vaAlMapa(mapDir): void {
    window.location.href=mapDir;
  }
}
